<template>
  <div class="bg-white sm:bg-main-bg min-h-full h-screen pb-12">
    <div class="px-4 lg:px-0 font-medium h-24 flex flex-col justify-center">
      <div class="max-w-4xl mx-auto w-full">
        <span @click.prevent="goToHome" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" />
        </span>
      </div>
    </div>
    <div class="max-w-md mx-auto px-4 md:px-0 mt-10">
      <div
        class="px-0 max-w-md sm:border border-border bg-white sm:py-8 sm:px-6 rounded-lg mx-auto"
      >
        <h3 class="pb-6 font-medium text-3x1 login--title text-title">
          Complete password reset
        </h3>
        <form @submit.prevent="completePasswordReset" class="mt-4">
          <div class="flex flex-col input-wrap">
            <label class="text-xs font-medium block text-sec-text mb-1"
              >Enter new password</label
            >
            <div class="flex items-center bg-main-bg w-full h-12 mb-6">
              <svg
                class="absolute ml-4"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 5.66659H11.3333C11.5101 5.66659 11.6797 5.73682 11.8047 5.86185C11.9298 5.98687 12 6.15644 12 6.33325V12.9999C12 13.1767 11.9298 13.3463 11.8047 13.4713C11.6797 13.5963 11.5101 13.6666 11.3333 13.6666H0.666667C0.489856 13.6666 0.320286 13.5963 0.195262 13.4713C0.0702379 13.3463 0 13.1767 0 12.9999V6.33325C0 6.15644 0.0702379 5.98687 0.195262 5.86185C0.320286 5.73682 0.489856 5.66659 0.666667 5.66659H1.33333V4.99992C1.33333 4.38708 1.45404 3.78025 1.68856 3.21406C1.92308 2.64788 2.26683 2.13343 2.70017 1.70009C3.13351 1.26675 3.64796 0.923003 4.21414 0.688481C4.78033 0.453959 5.38716 0.333252 6 0.333252C6.61284 0.333252 7.21967 0.453959 7.78586 0.688481C8.35204 0.923003 8.86649 1.26675 9.29983 1.70009C9.73317 2.13343 10.0769 2.64788 10.3114 3.21406C10.546 3.78025 10.6667 4.38708 10.6667 4.99992V5.66659ZM1.33333 6.99992V12.3333H10.6667V6.99992H1.33333ZM5.33333 8.33325H6.66667V10.9999H5.33333V8.33325ZM9.33333 5.66659V4.99992C9.33333 4.11586 8.98214 3.26802 8.35702 2.6429C7.7319 2.01777 6.88406 1.66659 6 1.66659C5.11595 1.66659 4.2681 2.01777 3.64298 2.6429C3.01786 3.26802 2.66667 4.11586 2.66667 4.99992V5.66659H9.33333Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-sec-text border border-input focus:border-primary hover:border-primary outline-none rounded-lg text-sm pl-10 pr-10"
                @change="borderFill"
                :type="!showPassword ? 'password' : 'text'"
                required
                placeholder="Enter new password"
                v-model="password"
              />
              <span
                @click.prevent="showPassword = !showPassword"
                class="cursor-pointer text-xs text-textColor -ml-10"
              >
                {{ !showPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>
          <div class="flex flex-col input-wrap">
            <label class="text-xs font-medium block text-title mb-1"
              >Confirm new password</label
            >
            <div class="flex items-center bg-main-bg w-full h-12 mb-6">
              <svg
                class="absolute ml-4"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 5.66659H11.3333C11.5101 5.66659 11.6797 5.73682 11.8047 5.86185C11.9298 5.98687 12 6.15644 12 6.33325V12.9999C12 13.1767 11.9298 13.3463 11.8047 13.4713C11.6797 13.5963 11.5101 13.6666 11.3333 13.6666H0.666667C0.489856 13.6666 0.320286 13.5963 0.195262 13.4713C0.0702379 13.3463 0 13.1767 0 12.9999V6.33325C0 6.15644 0.0702379 5.98687 0.195262 5.86185C0.320286 5.73682 0.489856 5.66659 0.666667 5.66659H1.33333V4.99992C1.33333 4.38708 1.45404 3.78025 1.68856 3.21406C1.92308 2.64788 2.26683 2.13343 2.70017 1.70009C3.13351 1.26675 3.64796 0.923003 4.21414 0.688481C4.78033 0.453959 5.38716 0.333252 6 0.333252C6.61284 0.333252 7.21967 0.453959 7.78586 0.688481C8.35204 0.923003 8.86649 1.26675 9.29983 1.70009C9.73317 2.13343 10.0769 2.64788 10.3114 3.21406C10.546 3.78025 10.6667 4.38708 10.6667 4.99992V5.66659ZM1.33333 6.99992V12.3333H10.6667V6.99992H1.33333ZM5.33333 8.33325H6.66667V10.9999H5.33333V8.33325ZM9.33333 5.66659V4.99992C9.33333 4.11586 8.98214 3.26802 8.35702 2.6429C7.7319 2.01777 6.88406 1.66659 6 1.66659C5.11595 1.66659 4.2681 2.01777 3.64298 2.6429C3.01786 3.26802 2.66667 4.11586 2.66667 4.99992V5.66659H9.33333Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-sec-text border border-input focus:border-primary hover:border-primary outline-none rounded-lg text-sm pl-10 pr-10"
                @change="borderFill"
                :type="!showConfirmPassword ? 'password' : 'text'"
                required
                placeholder="Confirm new password"
                v-model="confirmPassword"
              />
              <span
                @click="showConfirmPassword = !showConfirmPassword"
                class="cursor-pointer text-xs text-textColor -ml-10"
              >
                {{ !showConfirmPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>
          <button
            id="login-btn"
            class="btn mt-8 flex items-center justify-center bg-primary w-full rounded-lg h-14 hover:bg-hover text-white font-medium text-sm"
          >
            <span id="spin" slot="spin" class="animate-spin hidden">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                  fill="white"
                  fill-opacity="0.7"
                />
              </svg>
            </span>
            <span id="check" slot="check" class="hidden mr-1">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                  fill="white"
                />
              </svg>
            </span>
            <span id="login" slot="value"> Change Password </span>
          </button>
        </form>
      </div>
    </div>
    <Response
      v-show="passwordResetSuccessful"
      id="response"
      wrapperClass="fixed h-screen z-50 top-0 left-0 right-0 bg-sec-bg"
      modalClass="modal-wrap max-w-xs mx-auto bg-white rounded-lg flex flex-col
    items-center justify-center mt-12 relative p-4"
      :modalTitle="responseSuccess.title"
      :modalText="responseSuccess.text"
    >
      <div class="mb-8 mx-0 flex justify-center" slot="modal-icon-success">
        <svg
          width="144"
          height="144"
          viewBox="0 0 144 144"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="72" cy="72" r="72" fill="#27BD8B" fill-opacity="0.08" />
          <circle cx="72" cy="72" r="56" fill="#CBEFE3" />
          <circle cx="72" cy="72" r="40" fill="#27BD8B" />
          <path
            d="M72 53.6666L85.695 56.71C86.4567 56.88 87 57.555 87 58.3366V74.9816C87 78.325 85.3283 81.4483 82.5467 83.3016L72 90.3333L61.4533 83.3016C58.67 81.4466 57 78.325 57 74.9833V58.3366C57 57.555 57.5433 56.88 58.305 56.71L72 53.6666ZM72 57.0816L60.3333 59.6733V74.9816C60.3333 77.21 61.4467 79.2916 63.3017 80.5283L72 86.3283L80.6983 80.5283C82.5533 79.2916 83.6667 77.2116 83.6667 74.9833V59.6733L72 57.0833V57.0816ZM79.42 65.7033L81.7783 68.06L71.1717 78.6666L64.1 71.595L66.4567 69.2383L71.17 73.9516L79.42 65.7016V65.7033Z"
            fill="white"
          />
        </svg>
      </div>
      <div slot="cta" class="mt-4 mb-2 text-center">
        <router-link to="/login" class="font-bold text-sm mx-auto text-primary">
          Return to login page
        </router-link>
      </div>
    </Response>
  </div>
</template>

<script>
  import Response from "../../components/partials/response-modal";
  import Cookies from "js-cookie";
  import { ApiInstance as api } from "../../utils";

  export default {
    name: "ResetPassword",
    components: {
      Response,
    },
    data() {
      return {
        showPassword: false,
        showConfirmPassword: false,
        passwordResetSuccessful: false,
        spinner: "",
        resetSuccess: "",
        success: "Success",
        responseSuccess: {
          title: `Success`,
          text: `Your password has been updated successfully`,
        },
        password: null,
        confirmPassword: null,
      };
    },
    head: {
      title: function () {
        return {
          inner: "Loanspot",
          complement: "Set new password",
        };
      },
      meta: [
        { name: "description", content: "Loanspot reset password" },
        { name: "application-name", content: "Loanspot" },
      ],
    },
    methods: {
      borderFill(e) {
        if (e.target.value === "") {
          e.target.style.border = "1px solid rgba(229, 233, 242, 1)";
        } else {
          e.target.style.border = "1px solid rgba(22, 86, 193, 0.5)";
        }
      },
      async completePasswordReset() {
        const spinner = document.getElementById("spin");
        const loginBtn = document.getElementById("login");
        spinner.classList.remove("hidden");
        loginBtn.classList.add("hidden");
        const response = await api.post("/auth/password/reset/complete", {
          password: this.password,
          confirmPassword: this.confirmPassword,
          id: this.$route.params.userId,
          token: this.$route.params.token,
        });
        spinner.classList.add("hidden");
        loginBtn.classList.remove("hidden");
        if (
          response.status == true &&
          response.data?.status === "success" &&
          response.data?.statusCode === 202
        ) {
          this.passwordResetSuccessful = true;
          this.password = null;
          this.confirmPassword = null;
        } else {
          let message;
          if (response.error.statusCode === 422) {
            const error = response.error.fields || response.error.errors;
            if (error && Object.keys(error).length > 0) {
              message = Object.values(error)[0];
            }
          }
          if ([500, 401].includes(response.error.statusCode)) {
              message = response.error.error || response.error.message;
          }
          this.$notie.alert({
            type: "error",
            text: message || "Invalid password reset link.",
            time: 4,
          });
        }
      },
      goToHome() {
        const token = localStorage.getItem("auth-token");

        if (token) {
          Cookies.set("auth-token", token, {
            path: "/",
            domian: ".loanspot.africa",
            sameSite: "lax",
          });
          window.location.assign(`https://loanspot.africa`);
        } else {
          window.location.assign(`https://loanspot.africa`);
        }
      },
    },
  };
</script>

<style lang="scss" scope>
@media (min-width: 640px) {
  .header-wrap {
    padding: 35px 10%;
  }
}
@media (min-width: 1440px) {
  .header-wrap {
    max-width: 1440px !important;
    margin: auto;
  }
}
.form-wrap {
  padding: 0 10%;
}
.login--register {
  font-family: SohneKraftig, sans-serif;
  font-size: 1rem;
}
.login--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}
.password-reset {
  font-family: SohneLeicht, sans-serif;
}
.input-wrap {
  font-family: SohneLeicht, sans-serif;
  label {
    font-family: SohneKraftig, sans-serif;
    margin-bottom: 4px;
  }
  span {
    font-family: SohneLeicht, sans-serif;
  }
}
.modal-wrap {
  min-height: 300px;
}
</style>